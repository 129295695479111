import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CitationLogementHome from '../components/Home/CitationLogement.home.jsx';
import CardHomeWidgetsBottom from '../components/Widget/CardsWidgetsBottom.jsx';
import TitleHome from '../components/Home/Title.home.jsx';
import AccordeonConceptConcergerie from '../components/Conciergerie/AccordeonConceptConcergerie.jsx';
import AccordeonConceptLocation from '../components/Logements/AccordeonConceptLocation.jsx';

import PartConciergerieHome from '../components/Home/PartConciergerie.home.jsx';

export default function Accueil() {
    const translate = useSelector((store) => store.Template.language);

    {
        translate === 'French' &&
            (document.title =
                'Location et Conciergerie Airbnb | Ma Maison Sereine');
    }
    {
        translate === 'English' &&
            (document.title =
                'Rental and Concierge Airbnb  | Ma Maison Sereine');
    }
    document.description =
        'Location de logements en AirBnb, Accueil et départ des voyageurs (check-in / check-out), Ménage , contrôle gouvernant.';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main data-testid="accueil-page-main" className="homepage bg-primary">
            <TitleHome />

            <div className="bg-white ">
                {' '}
                <hr className="my-3" />
                <p className=" w-50 mx-auto my-5 fs-4">
                    {translate === 'French' && (
                        <>
                            <span className="font-title fs-1">
                                {' '}
                                Ma Maison Sereine
                            </span>{' '}
                            sera bientôt de retour,{' '}
                            <span className=" ms-2 font-title fs-1">
                                avec Vous
                            </span>
                            ,
                            <br />
                            car vous accompagner et vous conseiller est notre
                            ADN, <br />
                            <span className="font-title fs-1">
                                à vos cotés
                            </span>{' '}
                            en faisant notre maximum pour accueillir
                            chaleureusement vos hôtes est notre cœur de métier.
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            <span className="font-title fs-1">
                                {' '}
                                My Serene Home
                            </span>{' '}
                            will be back soon,{' '}
                            <span className="font-title  fs-1">with you</span>,
                            since you accompany and advise you is our DNA,{' '}
                            <span className="font-title  fs-1">
                                by your side
                            </span>{' '}
                            because do our best to warmly welcome your hosts is
                            our core business.
                        </>
                    )}
                </p>{' '}
                <hr className="my-3" />
            </div>
            <section className="d-flex flex-row flex-wrap mb-5">
                <AccordeonConceptConcergerie />
                <AccordeonConceptLocation />
            </section>
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
