import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FiPaperclip } from 'react-icons/fi';
import { GiNewspaper } from 'react-icons/gi';
import logoPure from '../../../assets/img/Pure/logo_pure.jpg';
import parkingPure from '../../../assets/img/Pure/parking_pure.jpg';
import portailPure from '../../../assets/img/Pure/portail_pure.jpg';
import qrcode_Pure from '../../../assets/qrcode/qrcode_Pure.png';
import qrcode_wifi_ser1 from '../../../assets/qrcode/qrcode_wifi_mms.png';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import Header from '../../../components/Header/Header.jsx';
import TitleBienvenue from '../../../components/Logements/bienvenue/Title.bienvenue.jsx';
import GpsBienvenue from '../../../components/Logements/bienvenue/Gps.bienvenue.jsx';
import ArriveeBienvenue from '../../../components/Logements/bienvenue/Arrivee.bienvenue.jsx';
import BoiteCleBienvenue from '../../../components/Logements/bienvenue/BoiteCle.bienvenue.jsx';
import InternetBienvenue from '../../../components/Logements/bienvenue/Internet.bienvenue.jsx';
import ChauffageBienvenue from '../../../components/Logements/bienvenue/Chauffage.bienvenue.jsx';
import JardinBienvenue from '../../../components/Logements/bienvenue/Jardin.bienvenue.jsx';
import ContactUrgence from '../../../components/Logements/ContactUrgence.jsx';
import DechetsBienvenue from '../../../components/Logements/bienvenue/Dechets.bienvenue.jsx';
import DurantSejourBienvenue from '../../../components/Logements/bienvenue/DurantSejour.bienvenue.jsx';
import DepartBienvenue from '../../../components/Logements/bienvenue/Depart.bienvenue.jsx';
import MerciBienvenue from '../../../components/Logements/bienvenue/Merci.bienvenue.jsx';

export default function BienvenuePure() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Bienvenue à Pure | Ma Maison Sereine';
    document.description =
        'Découvrez les recommandations pour profiter au mieux de votre logement lors de votre séjour.';
    const [className] = useState(
        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
    );
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            <Header />
            <main className="bg-primary ">
                <div className="d-flex flex-row justify-content-around align-items-center pt-4">
                    <img
                        src={logoPure}
                        alt={'Logo - Pure'}
                        title={'Logo - Pure'}
                        loading="lazy"
                        width={'180px'}
                        className="card-location-logo mt-3 rounded-2 border border-1 border-primary"
                    />
                    <div className=" screen-none_qrcode">
                        <img
                            src={qrcode_Pure}
                            alt="QRCode Pure"
                            width={'190px'}
                            className="card-location-logo mt-4 pt-3"
                        />
                        <small className="text-white mt-2">
                            Version Mobile & <span>English Version</span>{' '}
                            <div className="fi fi-gb"></div>
                        </small>
                    </div>
                </div>
                <TitleBienvenue className={className} title={'Pure'} />
                <GpsBienvenue
                    className={className + ' no-print'}
                    address={'23 Rue Tartifume 44210 Pornic'}
                    gpsGoogle={'https://goo.gl/maps/MBXnKtZeU6xZaRHY6'}
                    gpsApple={
                        'https://maps.apple.com/?address=23%20Rue%20Tartifume,%2044210%20Pornic,%20France&ll=47.116485,-2.101810&q=23%20Rue%20Tartifume'
                    }
                    gpsWaze={
                        'https://ul.waze.com/ul?place=ChIJcVBPzioNBUgRdpOFFzBzJeE&ll=47.11660470%2C-2.10163600&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location'
                    }
                />
                <BoiteCleBienvenue
                    className={className}
                    boiteFr={
                        <>
                            <p>
                                Un accueil en présentiel avec une personne de
                                l'équipe sera programmé avec vous pour vous
                                sentir tout de suite comme chez vous.
                            </p>
                            <p>
                                L'appartement se situe au 2ème étage, porte N°4.
                                Le batiment dispose d'un ascenseur.
                            </p>
                        </>
                    }
                    boiteEn={
                        <>
                            <p>
                                A face-to-face reception with a person of the
                                team will be programmed with you for immediately
                                feel at home.
                            </p>
                            <p>
                                The apartment is located on the 2nd floor, door
                                #4. The building has an elevator.
                            </p>
                        </>
                    }
                />
                <ArriveeBienvenue
                    className={className}
                    presentiel={true}
                    hourFr={'17h00'}
                    hourEn={'5p.m.'}
                    parkingFr={
                        <>
                            <p>
                                Une place de parking vous est dédiée dans la
                                cour intérieure (non couverte). Adresse du
                                portail idem au logement (10 mètres avant
                                environ) Une fois devant celui-ci, appuyer sur
                                le badge fourni. Merci de vous garer sur la
                                place indiquée sur la photo.
                            </p>
                            <p>
                                ATTENTION: c’est étroit de chaque côté. Si
                                Fabien arrive à garer son Kangoo, comme il n’est
                                pas délicat, c’est à la portée de tous 😊.
                            </p>
                        </>
                    }
                    parkingEn={
                        <>
                            {' '}
                            <p>
                                A parking space is dedicated to you in the
                                courtyard interior (not covered). Portal address
                                same as housing (about 10 meters before) Once in
                                front of this one, press the badge provided.
                                Thank you park in the space indicated in the
                                photo.
                            </p>
                            <p>
                                WARNING: it is narrow on each side. If Fabien
                                arrives to park his Kangoo, as it is not
                                delicate, it is the reach of all 😊.
                            </p>
                        </>
                    }
                    alertFr={
                        <>
                            Dès que vous sortez de l'appartement, pensez-bien à
                            prendre les clés avec vous, des portes sécurisées
                            empêchent les intrusions ! Ne vous retrouvez pas à
                            la porte !
                        </>
                    }
                    alertEn={
                        <>
                            As soon as you leave the apartment, remember to take
                            the keys with you, secure doors prevent intrusions!
                            Don't end up at the door!
                        </>
                    }
                    photos={
                        <>
                            {' '}
                            <div className="d-flex flex-row flex-wrap justify-content-around align-items-center mx-auto">
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={portailPure}
                                        alt={'portail - Pure'}
                                        title={'portail - Pure'}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />
                                    {translate === 'French' && (
                                        <small>Portail d'accès </small>
                                    )}
                                    {translate === 'English' && (
                                        <small>Access portal </small>
                                    )}
                                </div>
                                <div className="d-flex flex-column justify-content-around align-items-center text-center">
                                    <img
                                        src={parkingPure}
                                        alt={'parking - Pure'}
                                        title={'parking - Pure'}
                                        loading="lazy"
                                        width={'250px'}
                                        className="card-location-logo rounded-4"
                                    />{' '}
                                    {translate === 'French' && (
                                        <small>
                                            Parking Sécurisé dans la cour
                                            intérieure{' '}
                                        </small>
                                    )}
                                    {translate === 'English' && (
                                        <small>
                                            Secure parking in the inner
                                            courtyard{' '}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                />

                <InternetBienvenue
                    className={className}
                    access={false}
                    wifiName={'Ma Maison Sereine'}
                    wifiPass={'mms44770'}
                    infoFr={
                        <>
                            <p>
                                Vous pouvez vous connecter au réseau Wifi de
                                l'appartement
                            </p>
                        </>
                    }
                    infoEn={
                        <>
                            <p>
                                You can connect to the Wifi network of the
                                apartment
                            </p>
                        </>
                    }
                />
                <ChauffageBienvenue
                    className={className}
                    consigneFr={
                        <>
                            <p>
                                Le Chauffage est automatique mais vous pouvez
                                augmenter ou baisser la température de consigne
                                en cas de besoins avec les touches + et - sur
                                les radiateurs.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Merci aux petits techniciens curieux de ne pas
                                toucher aux boutons (ni aux disjoncteurs ! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                L'Energie est notre Avenir. Economisons-là
                            </p>
                        </>
                    }
                    consigneEn={
                        <>
                            <p>
                                Heating is automatic but you can increase or
                                lower the set temperature if necessary with the
                                + and - keys on the radiators.
                            </p>

                            <p className="text-center fw-bold text-danger">
                                Thank you to the curious little technicians not
                                to touch the buttons (or the circuit breakers! )
                            </p>

                            <p className="text-center fw-bold text-primary">
                                Energy is our Future. Let's save it
                            </p>
                        </>
                    }
                    dijoncteurFR={"dans le placard présent dans l'entrée"}
                    dijoncteurEN={'in the cupboard in the entrance'}
                    linkyFR={"au même niveau que le dijoncteur, dans l'entrée"}
                    linkyEN={
                        'at the same level as the circuit breaker, in the entrance'
                    }
                />
                <JardinBienvenue
                    className={className}
                    jardinFr={
                        <>
                            <p>
                                Comme dans toutes résidences, attention aux
                                bruits démesurés, principalement dans les
                                parties communes.
                            </p>
                            <p>
                                Je rappel que les fêtes sont interdites, en
                                effet les mamies de la résidence vont rallées.
                                Merci pour leur calme.
                            </p>
                        </>
                    }
                    jardinEn={
                        <>
                            <p>
                                As in all residences, pay attention to the
                                excessive noise, mainly in the common parts.
                            </p>
                            <p>
                                I remind you that parties are prohibited, in
                                effect the grannies of the residence go rallies.
                                Thank you for their calm.
                            </p>
                        </>
                    }
                    light={false}
                />
                <DechetsBienvenue
                    className={className}
                    dechetsFr={
                        <p>
                            Le Local Poubelle se trouve au niveau du parking /
                            Garage - à côté de l'emplacement N°3 . Merci de
                            penser à jeter votre poubelle à la fin de votre
                            séjour svp (Local poubelle près du garage numéro 3).
                        </p>
                    }
                    dechetsEn={
                        <p>
                            The Local Garbage can be found at the level of the
                            car park / Garage - next to pitch N°4. Thanks for
                            think about throwing your trash at the end of your
                            stay please (Garbage room near garage number 3).
                        </p>
                    }
                />
                <ContactUrgence className="bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 print-cut-none " />
                <DurantSejourBienvenue
                    className={
                        'bg-white border border-1 border-primary rounded my-3 pt-2 pb-4 px-4 mx-auto col-11 col-lg-8 '
                    }
                    duringFr={
                        <>
                            <p>
                                L’appartement est situé en plein centre-ville,
                                vous trouverez tout à proximité pour passer
                                d’excellentes vacances. Le Marché de Pornic se
                                tient tous les jeudis matin et dimanches matin
                                sur la place du marché.
                            </p>
                            <p>
                                Le magasin le plus proche pour faire de petite
                                course est devant l’église. Pharmacie la plus
                                proche est dans la rue qui descend sur le port.
                            </p>
                            <p>
                                Et pour trouver de bonnes bouteilles et de
                                précieux conseils il faut aller voir Hélène à sa
                                cave (La CAVE NATURE 30 Rue des Sables, 44210
                                Pornic - lien GPS dans les bons-plans de Fabien
                                - Elle vous accueille du mardi au dimanche
                                matin)pour vous faire partager sa passion et ses
                                connaissances des bons vins. Par moment, elle
                                est aussi là pour vous donner les clés ou les
                                récupérer car c’est la Fille de Jacqueline, la
                                propriétaire de Pure.
                            </p>
                        </>
                    }
                    duringEn={
                        <>
                            <p>
                                The apartment is located in the downtown, you
                                will find everything proximity to spend
                                excellent vacation. The Pornic Market is held
                                every Thursday mornings and Sunday mornings on
                                the market place.
                            </p>
                            <p>
                                The nearest store to make small race is in front
                                of the church. Pharmacy the closest is in the
                                street going down on the Haven.
                            </p>
                            <p>
                                And to find good bottles and precious advice you
                                have to go see Hélène in its cellar (La CAVE
                                NATURE 30 Rue des Sables, 44210 Pornic - She
                                welcomes you from Tuesday to Sunday morning) to
                                share with you his passion and knowledge of good
                                wines. At times, she is also there for give you
                                the keys or collect them because it is the
                                daughter of Jacqueline, the owner of Pure.
                            </p>
                        </>
                    }
                />
                <DepartBienvenue
                    className={className}
                    hourFr={'10h00'}
                    hourEn={'10a.m.'}
                    remiseCleFr={
                        "Merci de bien tout fermer et mettre les clés dans la boite aux lettres N°4 au nom de Karakula. Je vous conseille de sortir en premier votre voiture de la résidence, puis repasser avec le badge dans le Hall d'entrée pour y glisser les clés dans la boîte aux lettes N°4"
                    }
                    remiseCleEn={
                        'Please close everything securely and put the keys in mailbox No. 4 in the name of Karakula. I advise you to take your car out of the residence first, then go back with the badge to the Entrance Hall to slip in the keys in mailbox No. 4'
                    }
                    alertFr={
                        <>
                            Vous devez rendre l'appartement propre comme à votre
                            arrivée sauf dans le cas d'un forfait ménage
                            souscrit au préalable.
                        </>
                    }
                    alertEn={
                        <>
                            You must leave the apartment clean as your arrival
                            except in the case of a cleaning fee subscribed to
                            the prior.
                        </>
                    }
                    forfaitMenageFr={
                        'Vous devez tout de même rendre le logement dans un état acceptable, vider vos poubelles ainsi que votre vaisselle propre et rangée. '
                    }
                    forfaitMenageEn={
                        'You must still leave the accommodation in an acceptable condition, empty your trash and your dishes clean and tidy'
                    }
                    faireMenageFr={
                        <>
                            <p>
                                Face au grand nombre d’incivilité et pour le
                                respect de nos techniciens, merci de bien
                                l’effectuer sous peine de litige.
                            </p>
                            <p>
                                « Sur une journée d’affluence un technicien peut
                                avoir jusqu’à 8 lieux à vérifier, imaginer qu’il
                                ne peut à chaque location refaire le ménage… le
                                tout entre 10h00 et 16h00 ! Soyons donc tous
                                vigilent et respectueux ! » Fabien.
                            </p>
                        </>
                    }
                    faireMenageEn={
                        <>
                            <p>
                                Faced with the large number of incivilities and
                                for the respect for our technicians, thank you
                                very much do so under penalty of litigation.
                            </p>
                            <p>
                                “On a busy day a technician can have up to 8
                                places to check, imagine that he cannot at each
                                rental redo the housework… all between 10:00
                                a.m. and 4:00 p.m.! So let's all be vigilant and
                                respectful! » Fabian.
                            </p>
                        </>
                    }
                    basesMenageFr={
                        <>
                            Aérer chaque pièce.
                            <ul>
                                <li>
                                    Dans les chambres : merci de retirer les
                                    draps (les mettre dans un sac en tissu) et
                                    nettoyer sol * et surfaces.
                                </li>
                                <li>
                                    Dans la salle de bain : vider la poubelle,
                                    mettre les serviettes dans le sac ainsi que
                                    le tapis. Nettoyer sol * et surfaces, bien
                                    essuyer la douche, lavabo, toilettes.
                                </li>
                                <li>
                                    Dans la cuisine : vider la poubelle,
                                    nettoyer le réfrigérateur, micro-ondes,
                                    four, surfaces, sol *…… <br />
                                    Mettre toute la vaisselle utilisée propre en
                                    évidence sur le plan de travail. Rendre la
                                    cuisine propre.
                                </li>
                                <li>
                                    Dans les pièces de vie : nettoyer les
                                    surfaces et sol *.
                                </li>
                            </ul>
                            <p>
                                * : Le nettoyage des sols consiste à aspirer et
                                laver avec la serpillère.
                            </p>
                        </>
                    }
                    basesHouseholdEn={
                        <>
                            Ventilate each room.
                            <ul>
                                <li>
                                    In the rooms: please remove the sheets (put
                                    them in a cloth bag) and clean floor * and
                                    surfaces.
                                </li>
                                <li>
                                    In the bathroom: empty the trash can, put
                                    the towels in the bag as well as the carpet.
                                    Clean floor * and surfaces, wipe the shower
                                    well, sink, toilet.
                                </li>
                                <li>
                                    In the kitchen: empty the trash, clean the
                                    refrigerator, microwave, oven, surfaces,
                                    floor *…… <br />
                                    Put all the dishes used clearly visible on
                                    the plan of work. Make the kitchen clean.
                                </li>
                                <li>
                                    In the living rooms: clean the surfaces and
                                    floor *.
                                </li>
                            </ul>
                            <p>
                                *: Floor cleaning involves vacuuming and wash
                                with the mop.
                            </p>
                        </>
                    }
                />
                <MerciBienvenue
                    className={className}
                    merciFr={
                        <>
                            Mon équipe et moi-même (et oui je fais du ménage
                            aussi) vous remercions par avance de votre
                            investissement dans cette tâche qui est la moins
                            drôle des vacances, mais qui fera notre sourire
                            <br />
                            <br />
                            Merci et un bon retour à vous !
                        </>
                    }
                    merciEn={
                        <>
                            My team and myself (and yes I do cleaning too) thank
                            you in advance for your investment in this task
                            which is the less funny of the holidays, but which
                            will make our smile <br />
                            <br />
                            Thank you and welcome back !
                        </>
                    }
                />
                <div className={className}>
                    <h2 className="text-center m-3 ">
                        <GiNewspaper />
                        {translate === 'French' && "Manuels / Modes d'emploi"}
                        {translate === 'English' &&
                            'Manuals / Instructions for use'}
                    </h2>

                    <div>
                        {translate === 'French' ? (
                            <p className="text-start">
                                Voici les manuels d'utilisation des appareils
                                présents dans le logement (Français uniquement):
                            </p>
                        ) : (
                            <p className="text-start">
                                Here are the device user manuals present in the
                                accommodation (French only):
                            </p>
                        )}

                        <ul className="text-start">
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Pure-Four.pdf'
                                        }
                                    >
                                        Cuisine : Four
                                    </a>
                                ) : (
                                    <p className="text-start">Kitchen: Oven</p>
                                )}
                            </li>
                            <li>
                                <FiPaperclip />{' '}
                                {translate === 'French' ? (
                                    <a
                                        className="text-start"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            'https://api.mamaisonsereine.fr/media/pdf/Pure-Faure_LVT311_Dishwasher.pdf'
                                        }
                                    >
                                        Cuisine : Lave-Vaisselle
                                    </a>
                                ) : (
                                    <p className="text-start">
                                        Kitchen: Dishwasher
                                    </p>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <section className="sect__widgets">
                    <CardHomeWidgetsBottom />
                </section>
            </main>
        </>
    );
}
